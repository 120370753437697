/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box; }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0; }

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0; }

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none; }

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */ }

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden] {
  display: initial; }

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute; }

body {
  background: white;
  color: #302621;
  font-family: "Source Sans Pro", sans-serif; }

a {
  color: #167d7f;
  text-decoration: underline;
  cursor: pointer; }

h1, h2, h3 {
  color: #167d7f;
  margin: 1em 0 .5em 0; }
  h1:first-child, h2:first-child, h3:first-child {
    margin-top: 0; }

button {
  margin: 8px 4px;
  background: #167d7f;
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 4px; }
  button svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 8px; }
  button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); }

.debug-sticky {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background: white;
  border: 1px solid silver;
  padding: 8px;
  font-size: 12px;
  color: black;
  font-family: monospace;
  text-align: right;
  opacity: 0; }
  .debug-sticky:hover {
    opacity: 1; }

.bubble {
  position: absolute;
  transform: translateX(50%);
  max-width: 200px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.4);
  opacity: 0;
  background: white;
  padding: 8px;
  transition: .2s opacity;
  border-radius: 4px; }
  .bubble--visible {
    opacity: 1; }
  .bubble--success {
    border: 4px solid #62d6ba; }
  .bubble--error {
    border: 4px solid #d66a61; }
  .bubble p {
    margin: 0.5em 0; }
  .bubble__buttons {
    display: flex; }

.form {
  max-width: 500px; }
  .form label {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 4px; }
  .form input, .form button, .form select {
    font-size: 24px;
    padding: 8px 16px;
    margin: 0 0 8px;
    border-radius: 4px; }
  .form input {
    border: 1px solid #167d7f; }
  .form input::placeholder {
    color: #bbb; }
  .form__error, .form__success {
    text-align: left;
    font-weight: bold;
    margin: 8px;
    display: none; }
    .form__error--visible, .form__success--visible {
      display: block; }
  .form__error {
    color: #a6352b; }
  .form__success {
    color: #2ba789; }

@media screen and (max-width: 600px) {
  .form {
    max-width: calc(100vw - 32px); } }

.table thead {
  color: #167d7f; }

.table tr:nth-child(2n) {
  background: #d8f2f2; }

.table th, .table td {
  padding: 4px 8px; }

.table--vertical th {
  text-align: left; }

.error {
  padding: 10vmin; }
  .error__message {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    font-size: 20px; }
    .error__message h1 {
      font-weight: normal; }
  .error__logo {
    font-family: 'Germania One', sans-serif;
    font-size: 6.6vmin;
    color: inherit;
    text-decoration: none; }

.outside {
  max-width: 800px;
  margin: 32px auto; }
  .outside__logo {
    width: 321px;
    height: 200px;
    display: block;
    margin: 0 auto; }
  .outside p {
    margin: 8px 4px; }

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .app__header {
    position: fixed;
    height: 32px;
    top: 0;
    left: 0;
    right: 0;
    background: #167d7f;
    color: white;
    padding: 8px;
    z-index: 1000;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }
    .app__header a {
      color: white;
      text-decoration: none; }
    .app__header-logo {
      font-family: 'Germania One', sans-serif;
      font-size: 24px;
      line-height: 0;
      margin-right: 16px; }
    .app__header-menu {
      display: flex;
      align-items: center;
      font-weight: 400; }
      .app__header-menu > *:not(:first-child) {
        margin-left: 8px; }
      .app__header-menu--right {
        margin-left: auto; }
    .app__header-button {
      color: white; }
      .app__header-button svg {
        width: 24px;
        height: 24px; }
    .app__header-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50vw; }
  .app__body-wrapper {
    position: relative;
    z-index: 0;
    flex: 1 1 auto;
    display: flex;
    margin-top: 32px; }
  .app__body {
    padding: 24px;
    width: 100%;
    max-width: 1000px; }
  .app__sidebar {
    width: 15vw;
    min-width: 200px;
    color: #666;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 16px 0; }
    .app__sidebar h3 {
      display: flex;
      align-items: center;
      margin: 0 0 4px 0;
      padding: 0 8px; }
      .app__sidebar h3 svg {
        height: 1em;
        margin-right: 0.5em; }
    .app__sidebar-menu {
      margin: 4px 0;
      padding: 0; }
      .app__sidebar-menu li {
        margin: 0;
        padding: 0; }
      .app__sidebar-menu-item {
        display: block;
        padding: 4px 8px;
        color: #302621;
        text-decoration: none; }
        .app__sidebar-menu-item:hover {
          background: #d8f2f2; }
        .app__sidebar-menu-item--active {
          background: #167d7f;
          color: white; }

.app-columns {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -8px;
  width: 100%; }
  .app-columns > * {
    flex: 1 1 40%;
    margin: 8px; }

.app-notice {
  padding: 16px;
  background: #d8f2f2; }

.app-main__dealer-link {
  font-size: 18px; }

.app-merchant__info-row {
  display: flex;
  flex-wrap: wrap; }
  .app-merchant__info-row div {
    margin-right: 24px; }
    .app-merchant__info-row div:last-child {
      margin-right: 0; }

.app-merchant__terminals-table {
  max-width: 600px; }
  .app-merchant__terminals-table tbody tr {
    cursor: pointer; }
  .app-merchant__terminals-table td:first-child {
    width: 20px; }
  .app-merchant__terminals-table td:nth-child(2) {
    width: 40px; }

.app-merchant__terminals-status {
  color: #999; }
  .app-merchant__terminals-status svg {
    width: 16px;
    height: 16px; }
  .app-merchant__terminals-status--online {
    color: #00ae00; }

.app-merchant__access-revoke {
  color: maroon; }

.app-terminal h1 svg {
  width: 0.8em;
  height: 0.8em; }

.app-terminal__info-row {
  display: flex;
  flex-wrap: wrap; }
  .app-terminal__info-row div {
    margin-right: 24px; }
    .app-terminal__info-row div:last-child {
      margin-right: 0; }

.app-terminal__viewer-column h2 {
  display: flex;
  justify-content: space-between; }

.app-terminal__viewer {
  display: flex;
  justify-content: center;
  align-items: center; }
  .app-terminal__viewer canvas {
    max-width: 380px;
    max-height: 300px; }
  .app-terminal__viewer-close {
    display: none;
    position: fixed;
    top: 64px;
    right: 32px; }
    .app-terminal__viewer-close svg {
      width: 32px;
      height: 32px;
      fill: #777; }
  .app-terminal__viewer--maximized {
    position: fixed;
    top: 32px;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1000; }
    .app-terminal__viewer--maximized canvas {
      max-width: calc(100vw - 100px);
      max-height: calc(100vh - 132px); }
  .app-terminal__viewer--maximized .app-terminal__viewer-close {
    display: block; }

.app-terminal__delete {
  display: inline-block;
  margin: 32px 0;
  color: maroon; }

@media screen and (max-width: 600px) {
  .app__header--have-location .app__header-logo {
    display: none; }
  .app__header--have-location .app__header-username {
    display: none; }
  .app__body-wrapper {
    flex-direction: column; }
  .app__sidebar {
    display: none; }
  .app-columns {
    flex-direction: column; }
    .app-columns > * {
      flex: 1 1 auto;
      width: 100%; } }

@media screen and (max-width: 800px) {
  .app-terminal__viewer--maximized canvas {
    max-width: calc(100vw - 16px);
    max-height: calc(100vh - 48px); } }

.auth {
  text-align: center;
  width: 400px;
  margin: auto; }
  .auth__alt {
    display: flex;
    justify-content: space-between; }

.landing__auth {
  padding: 16px;
  text-align: center;
  font-size: 120%; }

.viewer-screen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .viewer-screen canvas {
    position: relative;
    z-index: 100; }
  .viewer-screen__click {
    position: absolute;
    z-index: 200;
    width: 100px;
    height: 100px;
    transform: scale(1);
    background-color: rgba(123, 123, 255, 0.5);
    border: 2px solid rgba(123, 123, 255, 0.9);
    opacity: 1;
    transition: 1s opacity, 1s transform;
    border-radius: 50%;
    pointer-events: none; }
    .viewer-screen__click--fade {
      opacity: 0;
      transform: scale(0.2); }
